var TweenMax = require('gsap/TweenMax');
var TimelineMax = require('gsap/TimelineMax');
var CSSPlugin = require('gsap/CSSPlugin');
var TextPlugin = require('gsap/TextPlugin');

jQuery(window).load(function() {

    $ = jQuery;

    //create a TimelineLite instance
    var loadingPage = document.getElementById( 'loading-page' );
    var firstPage = document.getElementById( 'first-page' );
    var secondPage = document.getElementById( 'second-page' );
    var thirdPage = document.getElementById( 'third-page' );
    var fourthPage = document.getElementById( 'fourth-page' );
    var fifthPage = document.getElementById( 'fifth-page' );
    var $label = $( '.illustration-label' );
    var $labelTitle = $( '.illustration-label .title' );
    var $labelDescription = $( '.illustration-label .description' );
    var labelLength = 1;
    var halfLabelLength = labelLength / 2;

    TweenMax.fromTo( $( loadingPage ), 1, { opacity: 1 }, { opacity: 0, ease: Linear.easeInOut } );
    TweenMax.fromTo( $( firstPage ), 1, { opacity: 0 }, { opacity: 1, ease: Linear.easeInOut } );
    TweenMax.set( $label, { scaleX: 0, opacity: 0, x: '60%' }, 0 );
    TweenMax.set( $labelTitle, { opacity: 0, x: '40px' }, 0 );
    TweenMax.set( $labelDescription, { opacity: 0, x: '40px' }, 0 );

    var tl = new TimelineMax( {
            pause: false,
            onComplete:function() {
                this.restart();
            }
        } );

    tl.to( $( firstPage ).find( '.one' ), 3.5, { y:"-50%", ease: Power1.easeIn, onStart: function() {

        var $liveScreenLink = $( '#live-screen-link' );
        var $labelLink = $label.find( 'a' );
        $liveScreenLink.attr( 'href', 'https://laternastudio.com/blog/portfolio/a-wholesome-glow-website/' );
        $labelLink.attr( 'href', 'https://laternastudio.com/blog/portfolio/a-wholesome-glow-website/' );

    } }, 1 );
    enterLabel( tl, 'A Wholesome Glow', 'Web Design & Development', 1 );

    tl.fromTo( $( firstPage ).find( '.one' ), 1, { opacity: 1 }, { opacity: 0, ease: Linear.easeInOut }, 3 );
    tl.fromTo( $( firstPage ).find( '.two' ), 1, { opacity: 0 }, { opacity: 1, ease: Linear.easeInOut }, 3.5 );
    tl.to( $( firstPage ).find( '.two' ), 3.5, { y:"-50%", ease: Power0.easeIn, force3D: true }, 3.5 );

    goToPage( tl, firstPage, secondPage, 'What Remains', '3D Modeling & Rigging', 6, 'https://laternastudio.com/blog/portfolio/what-remains/' );
    tl.fromTo( $( secondPage ).find( '.one' )[0], 4, { x: '0%', scale: 1 }, { x: '-20%', scale: 1.05, ease: Linear.easeInOut }, 6 );
    tl.fromTo( $( secondPage ).find( '.two' )[0], 1, { opacity: 0 }, { opacity: 1, ease: Linear.easeInOut }, 9 );
    tl.fromTo( $( secondPage ).find( '.two' )[0], 5, { scale: 1.2 }, { scale: 1, ease: Linear.easeInOut }, 9 );

    goToPage( tl, secondPage, thirdPage, 'Viable Options', 'Custom WordPress Development', 13 );
    tl.fromTo( $( thirdPage ).find( 'img' ), 4.5, { y: '0%' }, { y: '-20%', ease: Linear.easeInOut }, 14 );

    generateCodeEditor( tl );
    goToPage( tl, thirdPage, fourthPage, 'Custom PHP Development', 'Web Design and Development', 18 );

    goToPage( tl, fourthPage, fifthPage, 'MedAire Medical Kit', '3D Modeling & Rigging', 24, 'https://laternastudio.com/blog/portfolio/medical-kit-visualization/' );
    tl.fromTo( $( fifthPage ).find( '.one' )[0], 5, { scale: 1.5 }, { scale: 1.1, ease: Linear.easeInOut }, 23 );
    tl.fromTo( $( fifthPage ).find( '.two' )[0], 1, { opacity: 0 }, { opacity: 1, ease: Linear.easeInOut }, 27 );
    tl.fromTo( $( fifthPage ).find( '.two' )[0], 5, { x: '0%', scale: 1 }, { x: '-30%', scale: 1.05, ease: Linear.easeInOut }, 27 );

    goToPage( tl, fifthPage, firstPage, undefined, undefined, 31 );
    tl.set( $( firstPage ).find( '.one' ), { y:"0%", opacity: 1 }, 31 );
    tl.set( $( firstPage ).find( '.two' ), { opacity: 0 }, 31 );

    $( 'body' ).on( 'pause_gsap', function() {
        tl.pause();
    } );

    $( 'body' ).on( 'resume_gsap', function() {
        tl.resume();
    } );
});

function goToPage( tl, oldPage, newPage, title, description, startTime, url )
{
    var $label = $( '.illustration-label' );
    var $labelTitle = $( '.illustration-label .title' );
    var $labelDescription = $( '.illustration-label .description' );

    var $liveScreenLink = $( '#live-screen-link' );
    var $labelLink = $label.find( 'a' );

    var transitionLength = 0.5;
    var labelLength = 1;
    var halfLabelLength = labelLength / 2;

    // Animate the old page off and the new page on
    tl.to( oldPage, transitionLength, { opacity: 0, ease: Linear.easeInOut }, startTime );
    tl.to( newPage, transitionLength, { opacity: 1, ease: Linear.easeInOut, onComplete: function() {

        var href = ( typeof url !== 'undefined' ) ? url : '#';
        $liveScreenLink.attr( 'href', href );
        $labelLink.attr( 'href', href );

    } }, startTime );

    // Animate the old label off
    var time = exitLabel( tl, startTime );

    if ( typeof title !== 'undefined' ) {
        enterLabel( tl, title, description, time );
    }

    return time - startTime;
}

/**
 * Make the label enter
 *
 * @param   {object}  tl           the Timeline object
 * @param   {string}  title        the new label title
 * @param   {string}  description  the new label description
 * @param   {int}     startTime    the timeline time when this should start
 * @return  {int}                  the time at the end of the animation
 */
function enterLabel( tl, title, description, startTime ) {
    var $label = $( '.illustration-label' );
    var $labelTitle = $( '.illustration-label .title' );
    var $labelDescription = $( '.illustration-label .description' );
    var transitionLength = 0.25;
    var labelLength = 1;
    var halfLabelLength = labelLength / 2;

    // Animate the new label on
    tl.to( $label, labelLength, { scaleX: 1, opacity: 1, x: '0%', ease: Power3.easeOut, onStart: function() {

        // Update the label text
        $labelTitle.text( title );
        $labelDescription.text( description );

    } }, startTime );

    tl.to( $labelTitle, halfLabelLength, { opacity: 1, x: '0', ease: Power2.easeOut }, startTime + ( labelLength * 0.4 ) );
    tl.to( $labelDescription, halfLabelLength, { opacity: 1, x: '0', ease: Power2.easeOut }, startTime + ( labelLength * 0.5 ) );
    return startTime + labelLength;
}

/**
 * Make the label leave
 *
 * @param   {object}  tl           the Timeline object
 * @param   {int}     startTime    the timeline time when this should start
 * @return  {int}                  the time at the end of the animation
 */
function exitLabel( tl, startTime ) {
    var $label = $( '.illustration-label' );
    var $labelTitle = $( '.illustration-label .title' );
    var $labelDescription = $( '.illustration-label .description' );
    var transitionLength = 0.25;
    var labelLength = 1;
    var halfLabelLength = labelLength / 2;

    tl.to( $label, labelLength, { scaleX: 0, opacity: 0, x: '60%', ease: Power3.easeOut }, startTime );
    tl.to( $labelTitle, halfLabelLength, { opacity: 0, x: '40px', ease: Power2.easeOut }, startTime + ( labelLength * 0.4 ) );
    tl.to( $labelDescription, halfLabelLength, { opacity: 0, x: '40px', ease: Power2.easeOut }, startTime + ( labelLength * 0.5 ) );

    return startTime + labelLength;
}

function renderTimeline( tl ) {
    $ = jQuery;
    var $tlGui = $( '<div id="gsap-tl-gui" />' );
    $( 'body' ).append( $tlGui );
    $tlGui
        .css( 'position', 'fixed' )
        .css( 'top', '1em' )
        .css( 'bottom', '1em' )
        .css( 'left', '1em' )
        .css( 'padding', '1em' )
        .css( 'background-color', 'rgba( 0, 0, 0, 0.7 )' )
        .css( 'color', 'white' )
        .css( 'width', '50%' )
        .css( 'box-sizing', 'border-box' )
        .css( 'overflow', 'scroll' )
        .css( 'z-index', '99' );

    var chil = tl.getChildren();

    chil.forEach(function( el ) {
        var $row = $( '<div class="gsap-ui-row" />');
        var $cell = $( '<div class="gsap-ui-cell" />');
        $row.append( $cell );

        var margin = ( el._startTime * 120 );
        var width = ( el._totalDuration * 120 );

        $cell
            .css( 'font-size', '9px' )
            .css( 'padding', '0.25em' )
            .css( 'overflow', 'hidden' )
            .css( 'text-overflow', 'ellipsis' )
            .css( 'white-space', 'nowrap' )
            .css( 'border', '1px solid white' )
            .css( 'margin-bottom', '1px' )
            .css( 'color', 'black' )
            .css( 'background-color', '#5c9cdb' )
            .css( 'position', 'relative' )
            .css( 'margin-left', margin + 'px' )
            .css( 'width', width + 'px' )
            .text( getPathTo( $( el.target )[0] ) )
            .attr( 'title', getPathTo( $( el.target )[0] ) );

        $tlGui.append( $row );
    });

}

function getPathTo(element) {
    if (element.id!=='')
        return '#'+element.id+'';
    if (element===document.body)
        return element.tagName;

    var ix= 0;
    var siblings= element.parentNode.childNodes;
    for (var i= 0; i<siblings.length; i++) {
        var sibling= siblings[i];
        if (sibling===element)
            return getPathTo(element.parentNode)+'/'+element.tagName+'['+(ix+1)+']';
        if (sibling.nodeType===1 && sibling.tagName===element.tagName)
            ix++;
    }
}

function generateCodeEditor( tl )
{
    var fourthPageText = document.getElementById( 'fourth-page-text' );
    var cursor = ' &block;';

    var consoleText = [
        {
            text: `
    <pre>
        <span class="reserved">if</span> ( <span class="function">array_key_exists</span>( $this->layout, self::<span class="function">getAdvancedLayouts</span>() ) ) {
            $type = "<span class="string">advanced</span>";
        }

        <span class="reserved">return</span> $type;
    }


    <span class="comment">/**
     * Get all of the routes currently in use on the site
     *
     * <span class="reserved">@return</span>  array  an array of used routes
     */</span>
    <span class="reserved">public static function</span> <span class="function">getActiveRoutes</span>()
    {
            `,
            time: 0.05,
            endDelay: 0,
            start: '-=2'
        },
        {
            text: `$allRoutes = <span class="function">array</span>();`,
            time: 0.5,
            pre: '        ',
            cursor: true,
        },
        {
            text: `$reservedRoutes = <span class="function">config</span>( '<span class="string">fletch.reserved_routes</span>' );`,
            time: 0.75,
            pre: '        ',
            cursor: true
        },
        {
            text: `$userCreatedRoutes = <span class="php-class">Page</span>::<span class="function">get</span>( <span class="function">array</span>( '<span class="string">route</span>' ) );`,
            time: 0.75,
            pre: '        ',
            cursor: true
        },
        {
            text: ` `,
            time: 0.05,
            pre: '',
            cursor: true
        },
        {
            text: `<span class="reserved">foreach</span>( $userCreatedRoutes as $userCreatedRoute ) {`,
            time: 0.75,
            pre: '        ',
            cursor: true
        },
        {
            text: `<span class="function">array_push</span>( $allRoutes, $userCreatedRoute->route );`,
            time: 0.75,
            pre: '            ',
            cursor: true
        },
        {
            text: `}`,
            time: 0.05,
            pre: '        ',
            cursor: true
        },
        {
            text: ` `,
            time: 0.05,
            pre: '',
            cursor: true
        },
        {
            text: `$allRoutes = <span class="function">array_merge</span>( $allRoutes, $reservedRoutes );`,
            time: 0.75,
            pre: '        ',
            cursor: true
        },
        {
            text: ` `,
            time: 0.05,
            pre: '',
            cursor: true
        },
        {
            text: `<span class="reserved">return</span> $allRoutes;`,
            time: 0.5,
            pre: '        ',
            cursor: true
        },
        {
            text: `}</pre>`,
            time: 0.05,
            pre: '    ',
            cursor: true
        }
    ];

    consoleText.forEach( function( item, index ) {

        var pre = '';
        for ( var i = 0; i < index; i++ ) {

            if ( consoleText[i].hasOwnProperty( 'pre' ) ) {
                pre += consoleText[ i ].pre;
            }

            pre +=  consoleText[ i ].text + '<br>';
        }

        if ( item.hasOwnProperty( 'pre' ) ) {
            pre += item.pre;
        }

        if ( item.hasOwnProperty( 'start' ) ) {
            var start = item.start;
        } else {
            var start = '+=0';
        }

        tl.to( fourthPageText, item.time, {
            text: item.text,
            ease:Linear.easeNone,
            onUpdate: function() {
                var modified = pre + this.target.innerHTML;

                if ( item.hasOwnProperty( 'cursor' ) && item.cursor ) {
                    modified += cursor;
                }

                this.target.innerHTML = modified;
            },
            onComplete: function() {
                this.target.innerHTML = pre + item.text;
            }
        }, start );

        // Add a delay, if one was specified
        if ( item.hasOwnProperty( 'endDelay' ) ) {
            tl.to( fourthPageText, item.endDelay, {} );
        }

        // Clear the previous text so that we can properly display the new text
        if ( index != consoleText.length - 1 ) {
            tl.to( fourthPageText, item.endDelay, {
                onComplete: function() {
                    this.target.innerHTML = '';
                }
            } );
        }
    } );
}